var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("div", { staticClass: "kt-portlet" }, [
              _c("div", { staticClass: "kt-portlet__head" }, [
                _c("div", { staticClass: "kt-portlet__head-label" }, [
                  _c(
                    "span",
                    { staticClass: "kt-portlet__head-icon" },
                    [
                      _c("SVGIcon", {
                        attrs: { name: "notification", "hex-color": "#0f88ef" },
                      }),
                    ],
                    1
                  ),
                  _c("h3", { staticClass: "kt-portlet__head-title" }, [
                    _vm._v(" Email Signatures "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c("form", { staticClass: "kt-form" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("SG User")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userName,
                          expression: "userName",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "paul" },
                      domProps: { value: _vm.userName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.userName = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Name")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.name,
                          expression: "name",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.name = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Title")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.title = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Line 1")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.subTitle1,
                          expression: "subTitle1",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.subTitle1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.subTitle1 = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Line 2")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.subTitle2,
                          expression: "subTitle2",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.subTitle2 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.subTitle2 = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("phone")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number" },
                      domProps: { value: _vm.phone },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.phone = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c("div", { staticClass: "kt-portlet" }, [
              _vm._m(0),
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c(
                  "table",
                  {
                    staticStyle: {
                      width: "400px",
                      "font-family": "'Open Sans', sans-serif",
                      "mso-line-height-rule": "exactly",
                      "border-collapse": "collapse",
                      "-webkit-text-size-adjust": "none",
                    },
                    attrs: {
                      width: "400",
                      border: "0",
                      cellspacing: "0",
                      cellpadding: "0",
                      "border-spacing": "0",
                    },
                  },
                  [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            padding: "10px",
                            background: "#1c242c",
                            "border-radius": "5px",
                          },
                          attrs: { width: "400" },
                        },
                        [
                          _c(
                            "table",
                            {
                              attrs: {
                                border: "0",
                                cellspacing: "0",
                                cellpadding: "0",
                              },
                            },
                            [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: { "text-align": "center" },
                                    attrs: { width: "170" },
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        attrs: {
                                          border: "0",
                                          cellspacing: "0",
                                          cellpadding: "0",
                                        },
                                      },
                                      [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "line-height": "11pt",
                                                "padding-bottom": "10px",
                                              },
                                              attrs: { width: "170" },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: { margin: "0" },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "https://www.syncgrades.com/",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          "moz-do-not-send":
                                                            "true",
                                                          border: "0",
                                                          width: "80",
                                                          height: "80",
                                                          src: `${_vm.fullHost}/images/signatures/signature.gif`,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "line-height": "11pt",
                                              },
                                              attrs: { width: "170" },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: { margin: "0" },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "https://www.syncgrades.com/",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          "moz-do-not-send":
                                                            "true",
                                                          border: "0",
                                                          width: "125",
                                                          src: `${_vm.fullHost}/images/signatures/large-white-logo.png`,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "padding-left": "20px",
                                      "border-left": "1px solid #339af2",
                                    },
                                    attrs: { width: "230" },
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        attrs: {
                                          border: "0",
                                          cellspacing: "0",
                                          cellpadding: "0",
                                        },
                                      },
                                      [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "line-height": "12pt",
                                                "padding-bottom": "5px",
                                              },
                                              attrs: { width: "230" },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: { margin: "0" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-family":
                                                          "'Open Sans', sans-serif",
                                                        "font-size": "12pt",
                                                        color: "#339af2",
                                                        "font-weight": "bold",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.name) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "line-height": "10pt",
                                                "padding-bottom": "5px",
                                              },
                                              attrs: { width: "230" },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: { margin: "0" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-family":
                                                          "'Open Sans', sans-serif",
                                                        "font-size": "9pt",
                                                        color: "#ffffff",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.title) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.subTitle1
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        margin: "0",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-family":
                                                              "'Open Sans', sans-serif",
                                                            "font-size": "9pt",
                                                            color: "#ffffff",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.subTitle1
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.subTitle2
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        margin: "0",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-family":
                                                              "'Open Sans', sans-serif",
                                                            "font-size": "9pt",
                                                            color: "#ffffff",
                                                            "font-weight":
                                                              "600",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.subTitle2
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "line-height": "11pt",
                                                "padding-top": "5px",
                                                "font-size": "10pt",
                                              },
                                              attrs: { width: "230" },
                                            },
                                            [
                                              _vm.phone
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        margin: "0",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticStyle: {
                                                            "font-family":
                                                              "'Open Sans', sans-serif",
                                                            "font-size": "9pt",
                                                            color: "#ffffff",
                                                            "text-decoration":
                                                              "none",
                                                          },
                                                          attrs: {
                                                            href: `tel:${_vm.phone}`,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.formattedPhone
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: { margin: "0" },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticStyle: {
                                                        "font-family":
                                                          "'Open Sans', sans-serif",
                                                        "font-size": "9pt",
                                                        color: "#ffffff",
                                                        "text-decoration":
                                                          "none !important",
                                                      },
                                                      attrs: {
                                                        href: `mailto:${_vm.email}`,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(_vm.email) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._m(1),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Preview "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { margin: "0" } }, [
      _c(
        "a",
        {
          staticStyle: {
            "font-family": "'Open Sans', sans-serif",
            "font-size": "9pt",
            color: "#339af2",
            "text-decoration": "none !important",
            "font-weight": "bold",
          },
          attrs: { href: "https://www.syncgrades.com/", target: "_blank" },
        },
        [_vm._v(" www.syncgrades.com ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }