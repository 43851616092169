import { render, staticRenderFns } from "./EmailSignatures.vue?vue&type=template&id=25f0b236&scoped=true"
import script from "./EmailSignatures.vue?vue&type=script&lang=ts"
export * from "./EmailSignatures.vue?vue&type=script&lang=ts"
import style0 from "../../css/colorSwatch.css?vue&type=style&index=0&lang=css&external"
import style1 from "./EmailSignatures.vue?vue&type=style&index=1&id=25f0b236&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25f0b236",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25f0b236')) {
      api.createRecord('25f0b236', component.options)
    } else {
      api.reload('25f0b236', component.options)
    }
    module.hot.accept("./EmailSignatures.vue?vue&type=template&id=25f0b236&scoped=true", function () {
      api.rerender('25f0b236', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rootComponents/super/EmailSignatures.vue"
export default component.exports