<template>
<div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-4">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <span class="kt-portlet__head-icon">
                                <SVGIcon
                                    name="notification"
                                    hex-color="#0f88ef"
                                />
                            </span>
                            <h3 class="kt-portlet__head-title">
                                Email Signatures
                            </h3>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <form class="kt-form">
                            <div class="form-group">
                                <label>SG User</label>
                                <input
                                    v-model="userName"
                                    type="text"
                                    class="form-control"
                                    placeholder="paul"
                                >
                            </div>
                            <div class="form-group">
                                <label>Name</label>
                                <input
                                    v-model="name"
                                    type="text"
                                    class="form-control"
                                >
                            </div>
                            <div class="form-group">
                                <label>Title</label>
                                <input
                                    v-model="title"
                                    type="text"
                                    class="form-control"
                                >
                            </div>
                            <div class="form-group">
                                <label>Line 1</label>
                                <input
                                    v-model="subTitle1"
                                    type="text"
                                    class="form-control"
                                >
                            </div>
                            <div class="form-group">
                                <label>Line 2</label>
                                <input
                                    v-model="subTitle2"
                                    type="text"
                                    class="form-control"
                                >
                            </div>

                            <div class="form-group">
                                <label>phone</label>
                                <input
                                    v-model="phone"
                                    type="number"
                                    class="form-control"
                                >
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Preview
                            </h3>
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <table
                            width="400"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                            border-spacing="0"
                            style="width: 400px; font-family: 'Open Sans', sans-serif; mso-line-height-rule:exactly; border-collapse:collapse; -webkit-text-size-adjust:none;"
                        >
                            <tr>
                                <td
                                    width="400"
                                    style="padding:10px; background: #1c242c; border-radius: 5px;"
                                >
                                    <table
                                        border="0"
                                        cellspacing="0"
                                        cellpadding="0"
                                    >
                                        <tr>
                                            <td
                                                width="170"
                                                style="text-align: center;"
                                            >
                                                <table
                                                    border="0"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                >
                                                    <tr>
                                                        <td
                                                            width="170"
                                                            style="line-height:11pt; padding-bottom:10px;"
                                                        >
                                                            <p style="margin: 0;">
                                                                <a href="https://www.syncgrades.com/">
                                                                    <img
                                                                        moz-do-not-send="true"
                                                                        border="0"
                                                                        width="80"
                                                                        height="80"
                                                                        :src="`${fullHost}/images/signatures/signature.gif`"
                                                                    >
                                                                </a>
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            width="170"
                                                            style="line-height:11pt; "
                                                        >
                                                            <p style="margin: 0;">
                                                                <a href="https://www.syncgrades.com/">
                                                                    <img
                                                                        moz-do-not-send="true"
                                                                        border="0"
                                                                        width="125"
                                                                        :src="`${fullHost}/images/signatures/large-white-logo.png`"
                                                                    >
                                                                </a>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td
                                                width="230"
                                                style="padding-left:20px; border-left:1px solid #339af2;"
                                            >
                                                <table
                                                    border="0"
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                >
                                                    <tr>
                                                        <td
                                                            width="230"
                                                            style="line-height:12pt; padding-bottom:5px;"
                                                        >
                                                            <p style="margin: 0;">
                                                                <span style="font-family: 'Open Sans', sans-serif; font-size: 12pt; color: #339af2; font-weight:bold;">
                                                                    {{ name }}
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            width="230"
                                                            style="line-height:10pt; padding-bottom:5px;"
                                                        >
                                                            <p style="margin: 0;">
                                                                <span style="font-family: 'Open Sans', sans-serif; font-size: 9pt; color: #ffffff;">
                                                                    {{ title }}
                                                                </span>
                                                            </p>
                                                            <p
                                                                v-if="subTitle1"
                                                                style="margin: 0;"
                                                            >
                                                                <span style="font-family: 'Open Sans', sans-serif; font-size: 9pt; color: #ffffff;">
                                                                    {{ subTitle1 }}
                                                                </span>
                                                            </p>
                                                            <p
                                                                v-if="subTitle2"
                                                                style="margin: 0;"
                                                            >
                                                                <span style="font-family: 'Open Sans', sans-serif; font-size: 9pt; color: #ffffff; font-weight: 600;">
                                                                    {{ subTitle2 }}
                                                                </span>
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td
                                                            width="230"
                                                            style="line-height:11pt; padding-top:5px; font-size:10pt;"
                                                        >
                                                            <p
                                                                v-if="phone"
                                                                style="margin: 0;"
                                                            >
                                                                <a
                                                                    :href="`tel:${phone}`"
                                                                    style="font-family: 'Open Sans', sans-serif; font-size: 9pt; color: #ffffff; text-decoration:none;"
                                                                >
                                                                    {{ formattedPhone }}
                                                                </a>
                                                            </p>
                                                            <p style="margin: 0;">
                                                                <a
                                                                    :href="`mailto:${email}`"
                                                                    style="font-family: 'Open Sans', sans-serif; font-size: 9pt; color: #ffffff; text-decoration: none !important;"
                                                                >
                                                                    {{ email }}
                                                                </a>
                                                            </p>
                                                            <p style="margin: 0;">
                                                                <a
                                                                    href="https://www.syncgrades.com/"
                                                                    target="_blank"
                                                                    style="font-family: 'Open Sans', sans-serif; font-size: 9pt; color: #339af2; text-decoration: none !important; font-weight:bold;"
                                                                >
                                                                    www.syncgrades.com
                                                                </a>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

export default {
    name: 'EmailSignatures',
    data() {
        const data = {
            userName: 'jed',
            name: 'Jed Lippold',
            host: null,
            title: 'Software Developer',
            subTitle1: 'SyncGrades - Lilo Consulting, LLC',
            subTitle2: 'NYS / NYC Certified MBE',
            phone: '7185551234',
        };
        data.host = window.location.host;
        if (data.host.includes('localhost')) {
            data.host = 'www.syncgrades.com';
        }
        return data;
    },
    computed: {
        email() {
            return `${(this.userName || '').toLowerCase()}@syncgrades.com`;
        },
        formattedPhone() {
            if (!this.phone) return '';
            return `(${this.phone.slice(0, 3)}) ${this.phone.slice(3, 6)}-${this.phone.slice(6)}`;
        },
        fullHost() {
            return `https://${this.host}`;
        },
    },
    methods: {
    },
};
</script>

<style src="../../css/colorSwatch.css" />

<style scoped>
.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: none;
    border-bottom: 1px solid #ebedf2;
}

thead span {
    cursor: pointer;
}

tbody tr td {
    cursor: pointer;
}

td.td-col-1 {
    width: 72px;
}

td.td-col-2 {
    width: 600px;
}

td.td-col-3 {
    width: 140px;
}

.kt-widget__media {
    margin-left: 40px;
}

.kt-media-group .kt-media {
    margin-left: -22px;
}

.kt-media-group .kt-media:first-child {
    margin-left: 40px;
}
</style>
